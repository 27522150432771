import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export function Piechart(props) {
  const labels = Object.keys(props);
  const dataValues = Object.values(props);

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'TCO',
        data: dataValues,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 165, 50, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 165, 50, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        align: 'center', // Align legend items to the center
        labels: {
          font: {
            size: 12,
            weight: 'bold',
          },
          padding: 10,
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.parsed || 0;
            const total = context.dataset.data.reduce((acc, data) => acc + data, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${label}: ${percentage}% ($${value.toLocaleString()})`;
          },
        },
      },
    },
    layout: {
      padding: {
        bottom: 5, // Add padding to the bottom
        right: 15 // Adjust this value to change the distance between chart and legend
      }
    }
  };

  return (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div className="chart-legend-container" style={{ 
      height: '130px', // Increase height slightly
      width: '100%', 
      maxWidth: '500px', 
      margin: '0 auto',
      padding: '5px' // Add padding to the container
    }}>
      <Pie data={data} options={options} />
    </div>
  </div>
  );
}