import './App.css';
import ProCalculator from './pages/pro_calculator/ProCalculator.js';
import HomePage from './pages/HomePage.js';
import SimpleCalculator from './pages/SimpleCalculator.js';
import SmoothScroll from 'smooth-scroll';
import CalculatorHomePage from './pages/CalculatorHomePage.js';
import LoginLanding from './pages/LoginLandingPage.jsx';
import CreateAccount from './pages/CreateAccount.js';
import ForgotPassword from './pages/ForgotPassword.js';
import UnderConstruction from './pages/UnderConstruction.js';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000, 
  speedAsDuration: true,
}); 

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/calculator" element={<CalculatorHomePage />} />
          <Route path="/basic" element={<SimpleCalculator />}/>
          <Route path="/SimpleCalculator" element={<SimpleCalculator />}/>
          <Route path="/pro" element={<ProCalculator />}/>
          <Route path="/login" element={<LoginLanding />}/> 
          <Route path="/create" element={<CreateAccount />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/construction" element={<UnderConstruction />}/>
        </Routes>
      </div>
    </Router>
  )
}

export default App;