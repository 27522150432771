import React from "react";
import { MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";

export const SidebarItem = ({ collapsed, icon, text, link }) => {
    return (
        <MenuItem component={<Link to={link}></Link>}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '10px' }}>
                {icon}
                {!collapsed && <span>{text}</span>}
            </div>
        </MenuItem>
    )
}