import React from "react";
import "./Table.css";

export const Table = ( categories, rows ) => {
    return (
        <div className="table-container">
            <table className="table">
                <tr className="table-header">
                    <th>Category 1</th>
                    <th>Category 2</th>
                    <th>Category 3</th>
                </tr>
                <tr className="table-content">
                    <td>1</td>
                    <td>2</td>
                    <td>3</td>
                </tr>
                <tr className="table-content">
                    <td>4</td>
                    <td>5</td>
                    <td>6</td>
                </tr>
            </table>
        </div>
    );
};