import '../App.css';
import React from "react";
import { useNavigate } from 'react-router-dom';
import { HomeButton } from '../components/HomeButton';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function LoginLanding () {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async(e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:8000/api/login/', {
                username, 
                password
            });
            localStorage.setItem('authToken', response.data.token);
            setError('');
            navigate('/');
        } catch (error) {
            setError('Invalid username or password');
        }
    }

    return (
        <div>
            <div className='row' style={{position: 'absolute', marginTop: 50}}></div>
            <HomeButton />
            <div id='login-landing' className="container">
                <div className="row vertical-center-row">
                    <div className="col-xs-4 col-xs-offset-4" style={{position: 'relative', alignContent: 'center', paddingTop: '5%'}}>
                        <div className="panel panel-default">
                            <div className="panel-heading text-center">
                                <h3 className="panel-title">Login</h3>
                            </div>
                            <div className="panel-body">
                                <form onSubmit={handleLogin}>
                                    <div className="form-group">
                                        <label htmlFor="email">Email or Username</label>
                                        <input type="text" className="form-control" id="email" placeholder="Enter email" onChange={(e) => setUsername(e.target.value)} required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input type="password" className="form-control" id="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required />
                                    </div>
                                    <button type="submit" className='btn btn-primary btn-block'>Login</button>
                                </form>
                                {error && <p className='text-danger'>{error}</p>}
                                <div className="text-center mt-3">
                                    <Link to="/forgot">Forgot your password?</Link>
                                </div>
                                <div className="text-center mt-3">
                                    <Link to="/create">Create an account</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginLanding;