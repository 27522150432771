import React, { useState, useEffect, useRef } from 'react';
import './ImageSlider.css'; // Import CSS for styling

const ImageSlider = ({ images, onTruckChange, selectedImage }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [toggledImages, setToggledImages] = useState(
    Array(images.length).fill(false) // Track toggled state for each image
  );
  const sliderRef = useRef(null);
  const timeoutRef = useRef(null); // To store the timeout ID for image toggling

  useEffect(() => {
    if (selectedImage) {
      const newIndex = images.findIndex(img => img.value === selectedImage.value || img.altValue === selectedImage.altValue);
      if (newIndex !== -1) {
        setCurrentImageIndex(newIndex);
      }
      timeoutRef.current = setTimeout(() => {
        setToggledImages((prevToggled) => {
          const newToggled = [...prevToggled];
          newToggled[currentImageIndex] = true; // Toggle the center image to its alternate
          return newToggled;
        });
      }, 1000); // 1-second delay for switching to the alternate image

      // Clear any previous timeouts to avoid unexpected behavior
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
    // Cleanup the timeout on component unmount
    return () => clearTimeout(timeoutRef.current);
  }, [selectedImage, images, currentImageIndex]);

  // Function to go to the previous image
  const handlePrevClick = () => {
    const newIndex =
      currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1;

    setCurrentImageIndex(newIndex);

    // Reset the toggled state for images other than the current one
    setToggledImages((prevToggled) =>
      prevToggled.map((toggled, i) => (i === newIndex ? toggled : false))
    );

    onTruckChange(images[newIndex].value); // Notify parent of the image change
  };

  // Function to go to the next image
  const handleNextClick = () => {
    const newIndex =
      currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1;

    setCurrentImageIndex(newIndex);

    // Reset the toggled state for images other than the current one
    setToggledImages((prevToggled) =>
      prevToggled.map((toggled, i) => (i === newIndex ? toggled : false))
    );

    onTruckChange(images[newIndex].value); // Notify parent of the image change
  };

  return (
    <div className="image-slider">
      <div className="slider-container">
        <button className="arrow-button left-arrow" onClick={handlePrevClick}>
          &lt;
        </button>
        <div className="images-wrapper" ref={sliderRef}>
            <div className="slider-image-container">
              <img
                src={
                  toggledImages[currentImageIndex] ? process.env.PUBLIC_URL + images[currentImageIndex].altUrl : process.env.PUBLIC_URL + images[currentImageIndex].url
                }
                alt={images[currentImageIndex].title}
                className="slider-image active"
                onClick={() => setToggledImages((prevToggled) => {
                  const newToggled = [...prevToggled];
                  newToggled[currentImageIndex] = !newToggled[currentImageIndex];
                  return newToggled;
                })}
                style={{ width: "200px", height: "100px" }} />
            </div>
        </div>
        <button className="arrow-button right-arrow" onClick={handleNextClick}>
          &gt;
        </button>
      </div>
      {currentImageIndex !== null && (
        <div className="image-info">
          {/* <h3 className='image-name'>{images[currentImageIndex].title}</h3> */}
          {/* <p>{images[currentImageIndex].description}</p> */}
        </div>
      )}
    </div>
  );
};

export default ImageSlider;
