import React from "react";
import { Sidebar, Menu } from 'react-pro-sidebar';
import './ProSidebar.css';
import { LuLayoutDashboard, LuBatteryCharging, LuClipboardList, LuSettings, LuArrowLeftSquare, LuArrowRightSquare } from "react-icons/lu";
import { CiDeliveryTruck } from "react-icons/ci";
import { HiOutlineHomeModern } from "react-icons/hi2";
import { CgProfile } from "react-icons/cg";
import { SidebarItem } from "./SidebarItem";

export const ProSidebar = ({ collapsed, toggleCollapse }) => {
    return (
        <div className="sidebar-container">
            <Sidebar collapsed={collapsed}>
                <div className="sidebar-content">
                    <Menu
                        menuItemStyles={{
                            button: {
                                [`&.active`]: {
                                    backgroundColor: "#DDE9EC",
                                    color: "#fff",
                                },
                            },
                        }}
                    >
                        
                        <div className="sidebar-content-items-group-1">
                            <SidebarItem collapsed={collapsed} icon={<LuLayoutDashboard size={25}/>} text="Dashboard" link="/" />
                            <SidebarItem collapsed={collapsed} icon={<CiDeliveryTruck size={25}/>} text="My fleet" link="/" />
                            <SidebarItem collapsed={collapsed} icon={<HiOutlineHomeModern size={25}/>} text="My depots" link="/" />
                            <SidebarItem collapsed={collapsed} icon={<LuBatteryCharging size={25}/>} text="Charging Stations" link="/" />
                            <SidebarItem collapsed={collapsed} icon={<LuClipboardList size={25}/>} text="Others" link="/" />
                        </div>
                        <div className="sidebar-content-items-group-2">
                            <SidebarItem collapsed={collapsed} icon={<CgProfile size={25}/>} text="Profile" link="/" />
                            <SidebarItem collapsed={collapsed} icon={<LuSettings size={25}/>} text="Settings" link="/" />
                        </div>
                    </Menu>
                </div>
                <div className="sidebar-footer">
                    <button onClick={toggleCollapse}>
                        {collapsed ? <LuArrowRightSquare size={25}/> : <LuArrowLeftSquare size={25}/>}
                    </button>
                </div>
            </Sidebar>
        </div>
    );
};