import '../App.css';
import React, { useState } from "react";
import { HomeButton } from '../components/HomeButton';
import axios from 'axios';

function ForgotPassword () {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:8000/api/forgotpwd/', { email });
            console.log(response, message);
            setMessage('Password reset link has been sent to your email');
        } catch (error) {
            setMessage('Error sending password reset link')
        }
    }

    return (
        <div>
            <HomeButton />
            <div id="create-account" className='container'>
                <div className='row vertical-center-row'>
                    <div className='col-xs-6 col-xs-offset-3'>
                        <h3>Forgot your password?</h3>
                        <form onSubmit={handleSubmit}>
                            <div className='form-group'>
                                <label for="inputEmail">Email or username</label>
                                <input 
                                    type="text" 
                                    className='form-control' 
                                    value={email} 
                                    onChange={(e) => setEmail(e.target.value)} 
                                    required />
                            </div>
                        </form>
                        <button type="submit" className='btn btn-default' onClick={handleSubmit}>Resend your password</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;