import '../../App.css'
import { ProSidebar } from '../../components/pro_calculator/ProSidebar';
import { useState } from 'react';
import { Table } from '../../components/pro_calculator/Table';

function ProCalculator() {
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div className='pro-calculator'>
            <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
            <div className='pro-calculator-content'>
                <div className='dashboard-container'>
                    <h1>Dashboard</h1>
                    <p>Data to be pulled from database and other pages</p>
                </div>
                <div id="map" className='map-container' style={{ height: '400px', width: '400px' }}>
                    <Table />
                </div>
            </div>
        </div>
    )
};

export default ProCalculator;