import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export const Navigation = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    axios.get('http://localhost:8000/api/user/', {
      headers: {
        Authorization: `Token ${localStorage.getItem('authToken')}`
      }
    })
    .then(response => {
      setUserData(response.data);
      setIsAuthenticated(true);
    })
    .catch(error => {
      console.log("User is not authenticated", error);
      setIsAuthenticated(false);
    });
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
    setUserData(null);
  }

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <Link className="navbar-brand page-scroll" to="/">
            <img src="../img/logo.png" alt="AmpTrans logo" className="img-responsive" width={'30%'}></img>
          </Link>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <Link to="/calculator" className="page-scroll">
                Solutions
              </Link>
            </li>
            {/* <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li> */}
            <li>
            {isAuthenticated ? (
    <div className="dropdown">
      <button
        className="user-btn btn dropdown-toggle"
        type="button"
        id="userDropdown"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={{ color: '#333', borderRadius: 20, paddingLeft: 20, paddingRight: 20 }}
      >
        {userData.first_name}
      </button>
      <ul className="dropdown-menu" aria-labelledby="userDropdown">
        <li>
          <Link to="/profile" className="dropdown-item">
            Profile
          </Link>
        </li>
        <li>
          <button className="dropdown-item" onClick={handleLogout}>
            Logout
          </button>
        </li>
      </ul>
    </div>
  ) : (
    <Link to="/construction" className="btn login-btn" style={{ color: 'white', borderRadius: 20, paddingLeft: 20, paddingRight: 20 }}>
      Login
    </Link>
  )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
