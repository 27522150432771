export default function InputGroup({
    label,
    description,
    id,
    defaultValue,
    value,
    onChange,
    type = 'text'
}) {
    return (
        <div className="input-group form-group row">
            <div className="row">
                <div className="col-sm-6">
                    <div className="row">
                        <label className="label">{label}</label>
                    </div>
                    <div className="row">
                        {description && (
                            <p
                                className="help-block label-description"
                                style={{ maxWidth: '300px', wordWrap: 'break-word', marginTop: '5px' }}
                                dangerouslySetInnerHTML={{ __html: description }}
                            />
                        )}
                    </div>
                </div>
                <div className="col-sm-6">
                    <input
                        type={type}
                        id={id}
                        className="label-input"
                        defaultValue={defaultValue.toString()}
                        value={value}
                        onChange={onChange}
                    />
                </div>
            </div>
        </div>
    );
}
//import React, { useState } from 'react';
//
//export default function InputGroup({
//  label,
//  description,
//  id,
//  defaultValue,
//  value,
//  onChange,
//  type = 'text'
//}) {
//  const [showDescription, setShowDescription] = useState(false);
//
//  const toggleDescription = () => {
//    setShowDescription(!showDescription);
//  };
//
//  return (
//    <div className="input-group form-group row">
//      <div className="row">
//        <div className="col-sm-6 text-right">
//          <label className="label">
//            {label}
//            {description && (
//              <span
//                className="info-icon"
//                onClick={toggleDescription}
//                style={{
//                  marginLeft: '5px',
//                  cursor: 'pointer',
//                  display: 'inline-block',
//                  width: '14px',
//                  height: '14px',
//                  borderRadius: '50%',
//                  backgroundColor: '#007bff',
//                  color: 'white',
//                  textAlign: 'center',
//                  fontSize: '11px',
//                  lineHeight: '16px',
//                  verticalAlign: 'super',
//                }}
//              >
//                ?
//              </span>
//            )}
//          </label>
//        </div>
//        <div className="col-sm-6">
//          <input
//            type={type}
//            id={id}
//            className="label-input"
//            defaultValue={defaultValue.toString()}
//            value={value}
//            onChange={onChange}
//          />
//        </div>
//      </div>
//      {showDescription && (
//        <p className="help-block label-description" style={{ marginTop: '5px' }}>
//          {description}
//        </p>
//      )}
//    </div>
//  );
//}



