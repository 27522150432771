import '../App.css'
import React from "react";
import { Link } from 'react-router-dom';
import { Navigation } from '../components/navigation';
import { SmallHeader } from '../components/smallHeader';
import JsonData from "../data/data.json";
// import { useState, useEffect } from "react";
// import axios from "axios";

function CalculatorHomePage() {
    // const [isAuthenticated, setIsAuthenticated] = useState(false);
    // const [hasLicense, setHasLicense] = useState(false);

    // useEffect(() => {

    // })

    return (
        <div>
            <Navigation />
            <SmallHeader data={JsonData.Header2} />
            <div className='calculator-home-page'>
                <div className="row calculator-container" style={{ marginTop: 30, paddingBottom: 100 }}>
                    <div className="calculator-content">
                        <h3 className="calculator-title">Basic</h3>
                        <h3 className='calculator-title'>Free</h3>
                        <ul className='calculator-list'>
                            <li className='calculator-description'>Single vehicle comparison: Electric vs. Diesel side-by-side</li>
                            <li className='calculator-description'>Customizable inputs: Vehicle types, incentives, interest rates, energy costs, and more.</li>
                            <li className='calculator-description'>Easy-to-use interface for quick TCO estimates.</li>
                        </ul>
                        <div className='calculator-button-container'>
                            <Link className='btn-calculator' to="/basic">Select Basic Calculator</Link>
                        </div>
                    </div>
                    <div className="calculator-content">
                        <h3 className="calculator-title">Pro</h3>
                        <h3 className='calculator-title'>$X.XX</h3>
                        <ul className='calculator-list'>
                            <li className='calculator-description'>Full fleet analysis: Leveraging your fleet trajectories or our trajectory database to provide a more accurate estimate.</li>
                            <li className='calculator-description'>Optimize savings: TOU rates, depot charging, solar, battery storage, and more.</li>
                            <li className='calculator-description'>Advanced planning: Integrate existing 3rd party charging infrastructure.</li>
                        </ul>
                        <div className='calculator-button-container'>
                            <Link className='btn-calculator' to="/construction">Select Pro Calculator</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CalculatorHomePage;