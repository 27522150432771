import React from "react";
import { Link } from "react-router-dom";

export const Solutions = () => {
    return (
        <div id="solutions" className="col-md-12" >
            <div className="row container" style={{ paddingTop: 50, paddingLeft: 50 }}>
                <h2>Solutions</h2>
                <p className="description" style={{ fontSize: 20, lineHeight: 1.75, color: "#252525" }}>We foster a deep understanding of the electric ecosystem through advanced modeling, AI, and data analysis - key to coordinating development effectively and efficiently.</p>
            </div>
            <div id="team" className="row image-text-group">
                <div className="image-container">
                    <img src="\img\homepage\fleet.png" alt="Tesla fleet" style={{ width: "100%", borderRadius: 50 }}></img>
                </div>
                <div className="text-container">
                    <h2>Fleet Owners</h2>
                    <p style={{ fontSize: 20, marginRight: 50, color: "#252525" }}><b>Empowering your fleet with cost-effective solutions for a seamless transition to electric trucks at one click.</b></p>
                    <Link to="/calculator" className="btn-calculator">Try Electric Fleet TCO Calculators</Link>
                    <ul className="calculator-list" style={{ marginRight: 50 }}>
                        <li className="calculator-description" style={{ fontSize: 20 }}>
                            <b>Route optimization: </b> Assess the feasibility of converting to electric trucks based on specific routes.
                        </li>
                        <li className="calculator-description" style={{ fontSize: 20 }}>
                            <b>Charging strategy: </b> Determine the best times and locations for charging using TOU rates and available third-party charging stations.
                        </li>
                        <li className="calculator-description" style={{ fontSize: 20 }}>
                            <b>Depot charging potential: </b> Evaluate the benefits and logistics of setting up depot charging stations.
                        </li>
                        <li className="calculator-description" style={{ fontSize: 20 }}>
                            <b>Renewable integration: </b> Explore the potential for integrating solar power and battery storage to reduce costs and enhance sustainability.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row image-text-group">
                <div className="text-container">
                    <h2>Charging Station Developers</h2>
                    <p style={{ fontSize: 20, marginRight: 50, color: "#252525" }}><b>Strategic guide to optimal locations with data-driven insights and growth potential.</b></p>
                    <a href="#contact" className="btn btn-custom btn-lg">Book a demo</a>
                    <ul className="calculator-list" style={{ marginRight: 50 }}>
                        <li className="calculator-description" style={{ fontSize: 20 }}>
                            <b>Optimal locations: </b> Identify the best sites for new charging stations.
                        </li>
                        <li className="calculator-description" style={{ fontSize: 20 }}>
                            <b>Power capacity: </b> Assess power system capacity availability.
                        </li>
                        <li className="calculator-description" style={{ fontSize: 20 }}>
                            <b>Data-driven </b> Leverage extensive truck trajectory datasets.
                        </li>
                        <li className="calculator-description" style={{ fontSize: 20 }}>
                            <b>Charger utilization: </b> Estimate utilization rates and growth potential.
                        </li>
                        <li className="calculator-description" style={{ fontSize: 20 }}>
                            <b>Renewable integration: </b> Evaluate potential for solar, battery, and microgrid integration.
                        </li>
                        <li className="calculator-description" style={{ fontSize: 20 }}>
                            <b>Future-proof: </b> Plan for scalable and sustainable infrastructure.
                        </li>
                    </ul>
                </div>
                <div className="image-container">
                    <img src="\img\homepage\charging_station.png" alt="Fleet in sunset" style={{ width: "100%", borderRadius: 50 }}></img>
                </div>
            </div>
            <div className="row image-text-group">
                <div className="image-container">
                    <img src="\img\homepage\electric_utility.png" alt="Utilities" style={{ width: "100%", borderRadius: 50 }}></img>
                </div>
                <div className="text-container">
                    <h2>Utilities</h2>
                    <p style={{ fontSize: 20, marginRight: 50, color: "#252525" }}><b>Plan for fleet-scale charging, optimize grid capacity, and leverage V2G for efficiency.</b></p>
                    <a href="#contact" className="btn btn-custom btn-lg">Book a demo</a>
                    <ul className="calculator-list" style={{ marginRight: 50 }}>
                        <li className="calculator-description" style={{ fontSize: 20 }}>
                            <b>Grid reinforcement: </b> Identify when, where, and by much upgrades are needed.
                        </li>
                        <li className="calculator-description" style={{ fontSize: 20 }}>
                            <b>Data-driven decisions: </b> Utilize extensive datasets on truck trajectories, charging stations, and power systems.
                        </li>
                        <li className="calculator-description" style={{ fontSize: 20 }}>
                            <b>V2G potential: </b> Evaluate vehicle-to-grid opportunities to minimize upgrades.
                        </li>
                        <li className="calculator-description" style={{ fontSize: 20 }}>
                            <b>Advanced integration: </b> Advanced spatio-temporal optimization models for precise planning and efficiency.
                        </li>
                    </ul>
                </div>
            </div>
            <div id="about" className="row image-text-group">
                <div className="text-container">
                    <h2>About us</h2>
                    <p style={{fontSize: 20, lineHeight: 1.75, color: "#252525"}}>At AmpTrans, Inc., we specialize in providing cutting-edge solutions for transportation electrification. Our mission is to empower fleet owners, charging station developers, and electric utilities with data-driven insights and advanced modeling techniques. We leverage extensive datasets on truck trajectories, charging stations, and power systems to optimize electric truck feasibility, charging strategies, and grid capacity planning. Our comprehensive approach includes evaluating renewable integration, estimating infrastructure upgrades, and harnessing the potential of V2G technology. With a team of AI and modeling experts and power systems engineers, we are committed to advancing sustainable transportation and enabling a seamless transition to electric vehicles for a greener future.</p>
                </div>
                <div className="image-container">
                    <img src="\img\homepage\sustainable.jpg" alt="Cartoon visual" style={{ width: "80%", borderRadius: 50 }}></img>
                </div>
            </div>
        </div>
    )
}