import React from "react";

export const SmallHeader = (props) => {
    return (
    <header id="sm-header">
        <div className="intro-sm">
          <div className="overlay">
            <div className="container">
              <div className="intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
              </div>
            </div>
          </div>
        </div>
    </header>
    )
}